// React
import React from 'react';

// Gatsby
import { useStaticQuery, graphql } from 'gatsby';

// Emotion / styling
import styled from '@emotion/styled';
import s from 'src/styles';

// Packages

// Utilities
import { localize, localizeLink } from 'utilities';
import { useTheme } from 'hooks';
import { transitionNavigate } from 'transition';

// Components
import SEO from 'components/seo';
import { PageNavigation } from 'components/pageNavigation';
import PageIntroduction from 'components/pageIntroduction';
import ImageAndText from 'components/imageAndText';
import Image from 'components/image';
import Button from 'components/button';

const LearnPageComponent = ({ pageContext, content, location }) => {
    const { locale } = pageContext;
    const { seoMetaTags, title, introduction, sections } = content;

    // Set theme
    const theme = useTheme(s.color('white'));

    return (
        <>
            <s.layout.PageContainer>
                <SEO {...{ seoMetaTags, locale }} />
                <ContentWrapper>
                    <PageNavigation {...{ theme }}>
                        <span>{title}</span>
                    </PageNavigation>
                    <PageIntroduction {...{ content: introduction }} />
                    {sections.map((section, index) => (
                        <ImageAndText
                            key={`${section.name}-${index}`}
                            {...{
                                title: section.name,
                                text: section.pageDescription,
                                imagePosition: index % 2 ? 'right' : 'left',
                                image: (
                                    <Image
                                        {...{
                                            source: section.pageImage.fluid,
                                            alt: section.name,
                                        }}
                                    />
                                ),
                                button: (
                                    <Button
                                        {...{
                                            onClick() {
                                                transitionNavigate({
                                                    from: location,
                                                    to: localizeLink(
                                                        `/${
                                                            section.pageLink[0]
                                                                .slug
                                                        }/`,
                                                        locale
                                                    ),
                                                });
                                            },
                                            type: 'secondary',
                                            label: section.pageLinkTitle,
                                            arrow: true,
                                        }}
                                    />
                                ),
                            }}
                        />
                    ))}
                </ContentWrapper>
            </s.layout.PageContainer>
        </>
    );
};

const ContentWrapper = styled.div`
    ${s.responsive.property('padding-top', {
        0: 100,
        30: 140,
    })};
`;

const NamedLearnPageComponent = props => {
    const { locale } = props.pageContext;
    const { allDatoCmsPageLearn } = useStaticQuery(graphql`
        query {
            allDatoCmsPageLearn {
                edges {
                    node {
                        id
                        locale
                        title
                        introduction
                        slug
                        seoMetaTags {
                            ...SEO
                        }
                        sections {
                            name
                            pageLinkTitle
                            pageDescription
                            pageImage {
                                fluid(maxWidth: 800) {
                                    ...GatsbyDatoCmsFluid_noBase64
                                }
                            }
                            pageLink {
                                ... on DatoCmsPageContact {
                                    slug
                                }
                                ... on DatoCmsPageCourse {
                                    slug
                                }
                                ... on DatoCmsPageGuide {
                                    slug
                                }
                                ... on DatoCmsPageAbout {
                                    slug
                                }
                                ... on DatoCmsPageStory {
                                    slug
                                }
                                ... on DatoCmsPageSustainability {
                                    slug
                                }
                            }
                        }
                    }
                }
            }
        }
    `);

    return (
        <LearnPageComponent
            {...{
                content: localize(allDatoCmsPageLearn, locale),
                ...props,
            }}
        />
    );
};

export default NamedLearnPageComponent;
