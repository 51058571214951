// React
import React from 'react';

// Emotion / styling
import styled from '@emotion/styled';
import s from 'src/styles';

import { lineBreaks } from 'utilities';

const PageIntroductionComponent = ({ content }) => {
    return (
        <>
            <PageIntroduction
                dangerouslySetInnerHTML={{ __html: lineBreaks(content) }}
            />
        </>
    );
};

const PageIntroduction = styled.div`
    ${s.grid.columns('width', {
        0: 4,
        20: 5,
        30: 10,
        40: 8,
    })};

    color: ${s.color('black').default()};
    ${s.fonts.style('h8')};
    margin-top: 1.5em;
    margin-bottom: 2em;
    display: block;
`;

export default PageIntroductionComponent;
